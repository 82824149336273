import React from 'react';
import auth, { Auth } from './modules/auth';
import stations, { Stations } from './modules/stations';
import reservation, { Reservation } from './modules/reservation';
import luggage, { Luggage } from './modules/luggage';
import units, { Units } from './modules/units';
import bookingDetail, { BookingDetail } from './modules/bookingDetail';
import arrangementTypes, { ArrangementTypes } from './modules/arrangementTypes';
import irregular, { Irregular } from './modules/irregular';
export type RootStore = {
  auth: Auth;
  stations: Stations;
  reservation: Reservation;
  luggage: Luggage;
  units: Units;
  bookingDetail: BookingDetail;
  arrangementTypes: ArrangementTypes;
  irregular: Irregular;
};

export const store: RootStore = {
  auth,
  stations,
  reservation,
  luggage,
  units,
  bookingDetail,
  arrangementTypes,
  irregular
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { auth, bookingDetail, stations };
