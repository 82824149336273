import {
  IIregular,
  CreateIrregularBookingReq,
  StationmixerCalculateAmountReq
} from '@sagano/station/services/types';
import { getters } from '@sagano/share/utils';
import { makeObservable, observable, action, computed, toJS } from 'mobx';
import stations from './stations';

const storage = sessionStorage;
const IRREGULAR = 'irregular';
export class Irregular {
  @observable irregular: IIregular = storage.getItem(IRREGULAR)
    ? JSON.parse(storage.getItem(IRREGULAR) || '')
    : {};
  constructor() {
    makeObservable(this);
  }
  @action
  setIrregular = (irregular: IIregular) => {
    Object.assign(this.irregular, irregular);
    storage.setItem(IRREGULAR, JSON.stringify(irregular));
  };
  @action
  setServiceName = (serviceName: string) => {
    this.irregular.serviceName = serviceName;
    storage.setItem(IRREGULAR, JSON.stringify(this.irregular));
  };
  @action
  clear = () => {
    this.irregular = {};
    storage.removeItem(IRREGULAR);
  };
  @computed
  get date() {
    return this.irregular.date;
  }
  @computed
  get serviceName() {
    return this.irregular.serviceName;
  }
  @computed
  get freeBooking() {
    return this.irregular.freeBooking;
  }
  @computed
  get participantGroup() {
    return toJS(this.irregular.participantGroup);
  }
  @computed
  get totalParticipants() {
    return this.participantGroup?.unitQuantityItems?.reduce((pre, cur) => {
      return pre + (cur.quantity || 0);
    }, 0);
  }
  @computed
  get luggageCount() {
    return this.irregular.luggageCount;
  }
  @computed
  get channel() {
    return this.irregular.channel;
  }

  @computed
  get calculateAmountReq(): StationmixerCalculateAmountReq {
    return {
      participantGroup: this.participantGroup,
      serviceDay: this.date,
      freeBooking: this.freeBooking,
      luggageCount: this.luggageCount,
      serviceId: this.serviceId
    };
  }
  @computed
  get createIrregularBookingReq(): CreateIrregularBookingReq {
    return {
      date: this.date,
      participantGroup: this.participantGroup,
      luggageCount: this.luggageCount,
      serviceId: this.serviceId,
      freeBooking: this.freeBooking,
      mutationId: getters.mutationId,
      soldStationId: stations.loginStationId,
      channel: this.channel
    };
  }
  @computed
  get serviceId() {
    return this.irregular.serviceId;
  }
}

export default new Irregular();
