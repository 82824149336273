import { computed } from 'mobx';
import { BaseAuth } from '@sagano/share/stores';

export class Auth extends BaseAuth {
  @computed
  get isStation() {
    return this.userGroup === 'station';
  }

  @computed
  get isStationAdmin() {
    return this.isAdmin && this.isStation;
  }

  @computed
  get isTrain() {
    return this.userGroup === 'train';
  }

  @computed
  get canLoginUserGroup() {
    return this.isStation || this.isTrain;
  }
}

export default new Auth();
