import {
  IReservation,
  SeatInfo,
  Standing,
  ISeatInfo,
  ISeatStandingInfo,
  SearchInventoriesFilterReq,
  SearchInventoriesRespService,
  StationmixerCalculateAmountReq,
  StationmixerCreateBookingReq
} from '@sagano/station/services/types';
import { makeObservable, observable, action, computed, toJS } from 'mobx';
import stations from './stations';

const storage = sessionStorage;
const RESERVATION = 'reservation';
export class Reservation {
  @observable reservation: IReservation = storage.getItem(RESERVATION)
    ? JSON.parse(storage.getItem(RESERVATION) || '')
    : {};
  constructor() {
    makeObservable(this);
  }
  @action
  setReservation = (reservation: IReservation) => {
    this.reservation = reservation;
    storage.setItem(RESERVATION, JSON.stringify(reservation));
  };
  @action
  setService = (service: SearchInventoriesRespService) => {
    this.reservation.service = service;
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @action
  getRservation = () => {
    return toJS(this.reservation);
  };
  @action
  setBulkReservationId = (bulkReservationId: string) => {
    this.reservation.bulkReservationId = bulkReservationId;
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @action
  setSeats = (seats: ISeatInfo[] | SeatInfo[]) => {
    this.reservation.seats = seats;
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @action
  setStandings = (standings: ISeatStandingInfo[] | Standing[]) => {
    this.reservation.standings = standings;
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @action
  clear = () => {
    this.reservation = {};
    storage.removeItem(RESERVATION);
  };
  @computed
  get wheelChairs() {
    return toJS(this.reservation.participantGroup?.wheelChairs) || [];
  }
  @computed
  get totalParticipants() {
    return this.reservation.participantGroup?.unitQuantityItems?.reduce(
      (pre, cur) => {
        return pre + (cur.quantity || 0);
      },
      0
    );
  }
  @computed
  get serviceDay() {
    return this.reservation.serviceDay;
  }
  @computed
  get fromStationId() {
    return this.reservation.fromStationId as string;
  }
  @computed
  get toStationId() {
    return this.reservation.toStationId as string;
  }
  @computed
  get participantGroup() {
    return toJS(this.reservation.participantGroup);
  }
  @computed
  get serviceName() {
    return this.service?.name?.shortName;
  }
  @computed
  get serviceId() {
    return this.reservation.serviceId;
  }
  @computed
  get ignoreFee() {
    return this.reservation.ignoreFee;
  }
  @computed
  get travellerUpdate() {
    return this.reservation.travellerUpdate;
  }

  @computed
  get searchInventoriesReq(): SearchInventoriesFilterReq {
    const {
      usingGroupArrangementType,
      freeBooking,
      serviceDay,
      fromStationId,
      toStationId
    } = this.reservation;
    return {
      serviceDay,
      fromStationId,
      toStationId,
      participantGroup: this.participantGroup,
      usingGroupArrangementType,
      freeBooking,
      luggageCount: this.luggageCount
    };
  }
  @computed
  get service() {
    return toJS(this.reservation.service);
  }
  @computed
  get calculateAmountReq(): StationmixerCalculateAmountReq {
    const { freeBooking, ignoreFee } = this.reservation;
    return {
      participantGroup: this.participantGroup,
      serviceDay: this.serviceDay,
      freeBooking,
      ignoreFee: ignoreFee,
      luggageCount: this.luggageCount,
      serviceId: this.service?.id
    };
  }
  @computed
  get note() {
    return this.reservation.note;
  }
  @computed
  get bulkReservationId() {
    return this.reservation.bulkReservationId;
  }
  @computed
  get luggageCount() {
    return this.reservation.luggageCount || 0;
  }
  @computed
  get createBookingReq(): StationmixerCreateBookingReq {
    return {
      ...this.searchInventoriesReq,
      bulkReservationId: this.bulkReservationId,
      note: this.note,
      luggageCount: this.luggageCount,
      soldStationId: stations.loginStationId
    };
  }
  @computed
  get seats() {
    return toJS(this.reservation.seats) || [];
  }
  @computed
  get standings() {
    return toJS(this.reservation.standings) || [];
  }
}

export default new Reservation();
