import {
  ILuggage,
  StationmixerCalculateAmountReq
} from '@sagano/station/services/types';
import { makeObservable, observable, action, computed, toJS } from 'mobx';

const storage = sessionStorage;
const LUGGAGE = 'luggage';
export class Luggage {
  @observable luggage: ILuggage = storage.getItem(LUGGAGE)
    ? JSON.parse(storage.getItem(LUGGAGE) || '')
    : {};
  constructor() {
    makeObservable(this);
  }
  @action
  setLuggage = (luggage: ILuggage) => {
    this.luggage = luggage;
    storage.setItem(LUGGAGE, JSON.stringify(this.luggage));
  };
  @computed
  get baseInfo() {
    return toJS(this.luggage.baseInfo);
  }
  @computed
  get serviceName() {
    return this.luggage.serviceName;
  }
  @computed
  get ignoreFee() {
    return this.luggage.ignoreFee;
  }
  @computed
  get travellerUpdate() {
    return this.luggage.travellerUpdate;
  }
  @computed
  get channel() {
    return this.luggage.channel;
  }
  @computed
  get calculateAmountReq(): StationmixerCalculateAmountReq {
    return {
      serviceDay: this.baseInfo?.date,
      ignoreFee: this.luggage.ignoreFee,
      luggageCount: this.baseInfo?.luggageCount,
      serviceId: this.baseInfo?.serviceId
    };
  }
  @computed
  get updateBookingReq() {
    return {
      luggageCount: this.baseInfo?.luggageCount,
      ignoreFee: this.luggage.ignoreFee,
      serviceDay: this.baseInfo?.date,
      serviceId: this.baseInfo?.serviceId,
      travellerUpdate: this.luggage.travellerUpdate
    };
  }
  @computed
  get luggageItems() {
    return toJS(this.luggage.luggageInfo?.unitQuantityItems) || [];
  }
  @computed
  get totalAmount() {
    return toJS(this.luggage.luggageInfo?.totalAmount) || 0;
  }
  @action
  clear = () => {
    this.luggage = {};
    storage.removeItem(LUGGAGE);
  };
}

export default new Luggage();
