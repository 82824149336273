import { makeObservable, observable, action, toJS, computed } from 'mobx';
import {
  SEAT_GROUP_STANDING,
  SEAT_GROUP_WHEELCHAIR
} from '@sagano/share/components';
import { stationService } from '@sagano/station/services';
import { IUnitByIdMap } from '@sagano/share/services/types';
import { OrderType, PaymentStatus } from '@sagano/station/services/const';
import { GetBookingDetails, GetNoteResp } from '@sagano/station/services/types';

export class BookingDetail {
  @observable bookingDetail: GetBookingDetails = {};
  unitMap: IUnitByIdMap = {};

  constructor() {
    makeObservable(this);
  }
  @action
  setBookingDetail(detail: GetBookingDetails) {
    this.bookingDetail = detail;
  }
  @action async getDetail(bookingId: string) {
    return stationService.getBookingDetails({ bookingId }).then(res => {
      this.setBookingDetail(res);
    });
  }
  @action
  setUnitMap(unitMap: IUnitByIdMap) {
    this.unitMap = unitMap;
  }
  @action
  setNotes(notes: GetNoteResp[]) {
    this.bookingDetail.notes = notes;
  }
  @action async listNote(bookingId: string) {
    return stationService.listNote({ bookingId }).then(res => {
      this.setNotes(res.notes || []);
    });
  }

  @computed
  get service() {
    return toJS(this.bookingDetail.bookingParams?.service) || {};
  }
  @computed
  get bookingParams() {
    return toJS(this.bookingDetail.bookingParams) || {};
  }
  @computed
  get participantGroup() {
    return toJS(this.bookingDetail.bookingParams?.participantGroup) || {};
  }
  @computed
  get bookingHistories() {
    return toJS(this.bookingDetail.bookingParamsHistories) || [];
  }

  @computed
  get receiptSeq() {
    return this.bookingDetail.receiptSeq;
  }
  @computed
  get receiptPrintable() {
    return this.bookingDetail.receiptPrintable;
  }

  @computed
  get bookedAt() {
    return this.bookingDetail.bookedAt;
  }
  @computed
  get freeBooking() {
    return this.bookingDetail.freeBooking;
  }
  @computed
  get usingGroupArrangementType() {
    return this.bookingDetail.usingGroupArrangementType;
  }

  @computed
  get notes() {
    return toJS(this.bookingDetail.notes) || [];
  }

  @computed
  get bookingStatus() {
    return this.bookingParams?.bookingStatus;
  }
  @computed
  get isPaymentConfirmed() {
    return this.bookingParams?.paymentStatus === PaymentStatus.PaymentConfirmed;
  }

  @computed
  get totalAmount() {
    return this.bookingParams?.totalAmount;
  }

  @computed
  get normalSeats() {
    return toJS(
      this.service.reservedSeats?.filter(
        seat =>
          seat.seatGroupId !== SEAT_GROUP_WHEELCHAIR &&
          seat.seatGroupId !== SEAT_GROUP_STANDING
      ) || []
    );
  }

  @computed
  get standingSeatsMap() {
    return toJS(
      this.service.standings?.reduce(
        (pre, cur) => {
          const physicalCarName = cur.physicalCarNameLabel?.shortName as string;
          pre[physicalCarName] = cur.quantity || 0;
          return pre;
        },
        {} as { [key: string]: number }
      ) || {}
    );
  }
  @computed
  get isNormal() {
    return this.bookingDetail.orderType === OrderType.Normal;
  }
  @computed
  get isLuggage() {
    return this.bookingDetail.orderType === OrderType.Luggage;
  }
  @computed
  get isIrregular() {
    return this.bookingDetail.orderType === OrderType.Irregular;
  }
  @computed
  get canUpdate() {
    return this.bookingDetail.canUpdate;
  }
  @computed
  get cancellable() {
    return this.bookingDetail.cancellable;
  }
}

export default new BookingDetail();
