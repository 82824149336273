import { Direction, IBaseCarProps } from './BaseCar.types';

type ISeatOrder = Record<string, number>;

export const SEAT_GROUP_WHEELCHAIR = 'wheelchair';
export const SEAT_GROUP_STANDING = 'standing';

export const WHEELCHAIR_IDS = ['wheelchair_01', 'wheelchair_02'];

export const SEAT_IDS = ['A', 'B', 'C', 'D'];

export const GROUP_ARRANGEMENT_TYPE_ID = '4';

export const HP_OTA_ARRANGEMENT_TYPE_ID = '3';

const SEAT_BOTTOM_ORDER: ISeatOrder = {
  B: 0,
  C: 1,
  A: 2,
  D: 3
};

const SEAT_TOP_ORDER: ISeatOrder = {
  A: 0,
  D: 1,
  B: 2,
  C: 3
};

export const SEAT_ORDER: Record<string, ISeatOrder> = {
  top: SEAT_TOP_ORDER,
  bottom: SEAT_BOTTOM_ORDER
};

export const SEAT_DIRECTION: Record<string, Direction> = {
  A: 'left',
  B: 'left',
  C: 'right',
  D: 'right'
};

export const carsInfo: IBaseCarProps[] = [
  {
    isHead: true,
    count: 14
  },
  {
    count: 16
  },
  {
    count: 15,
    hasWheelchair: true
  },
  {
    count: 16
  },
  {
    count: 15,
    hasWheelchair: true
  }
];
