import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Menu } from '@linktivity/link-ui';
import styles from './sidebar.module.css';

const Sidebar = observer(() => {
  const { t } = useTranslation();

  return (
    <aside className={styles.sidebar}>
      <div className={styles.inner}>
        <Menu className={styles.menu} accordion>
          <Menu.MenuItem label={t('views.dashboard.title')} to="/dashboard" />
          <Menu.SubMenu
            label={t('layouts.sidebar.personalManager')}
            name="personalManager"
          >
            <Menu.MenuItem
              label={t('views.searchInventories.title')}
              to="/search-inventories"
            />
            <Menu.MenuItem
              label={t('views.irregularBooking.title')}
              to="/irregular-booking"
            />
            <Menu.MenuItem
              label={t('views.luggage.title')}
              to="/luggage-booking"
            />
            <Menu.MenuItem
              label={t('views.searchBookings.title')}
              to="/bookings"
            />
          </Menu.SubMenu>
          <Menu.SubMenu
            label={t('layouts.sidebar.serviceManager')}
            name="serviceManager"
          >
            <Menu.MenuItem label={t('views.qrSearch.title')} to="/qr-search" />
            <Menu.MenuItem label={t('views.report.title')} to="/report" />
          </Menu.SubMenu>
        </Menu>
      </div>
    </aside>
  );
});

export default Sidebar;
