import { observable, action, computed, makeObservable } from 'mobx';
import { Stations as BaseStations } from '@sagano/share/stores';
import { HOZUKYO } from '@sagano/station/services/const';
import { IOption } from '@sagano/share/types';

const storage = localStorage;

const LOGIN_STATION = 'loginStation'; // just for station system

export class Stations extends BaseStations {
  @observable loginStation: IOption = JSON.parse(
    storage.getItem(LOGIN_STATION) || '{}'
  );
  constructor() {
    super();
    makeObservable(this);
  }
  @action
  setLoginStation = (station: IOption) => {
    storage.setItem(LOGIN_STATION, JSON.stringify(station));
    this.loginStation = station;
  };

  @computed
  get loginStationId() {
    return this.loginStation.value;
  }
  @computed
  get loginStationName() {
    return this.loginStation.label;
  }
  @computed
  get loginOptions() {
    return this.downStations
      .map(station => ({
        value: station.stationId as string,
        label: station.stationLabel || ''
      }))
      .filter(station => station.value !== HOZUKYO);
  }
}

export default new Stations();
