import { FirebaseAuth } from '@linktivity/link-utils';
import { message } from '@linktivity/link-ui';
import i18n from '@sagano/station/i18n';
import { auth } from '@sagano/station/stores';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN
};

const tenantId = import.meta.env.VITE_APP_FIREBASE_TENANT_ID;

class StationAuth extends FirebaseAuth {
  constructor() {
    super(firebaseConfig);
    this.setTenantId(tenantId);

    this.auth.onAuthStateChanged(user => {
      if (user) {
        auth.setUser(user);
      } else {
        auth.clearUser();
        auth.clearAllStorage();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showErrorMessage(error: any) {
    const errorCode = error.code;
    let errorMessage = error.message;
    if (i18n.exists(`share.firebase.${errorCode}`)) {
      errorMessage = i18n.t(`share.firebase.${errorCode}`);
    }
    message.error(errorMessage);
  }
}

const stationAuth = new StationAuth();

export const storage = stationAuth.storage;
export default stationAuth;
