import { computed } from 'mobx';
import { ArrangementTypes as BaseArrangementTypes } from '@sagano/share/stores';
import { SeatType } from '@sagano/share/const';

export class ArrangementTypes extends BaseArrangementTypes {
  @computed
  get stationTypes() {
    return this.arrangementTypes.filter(type => {
      return (
        SeatType.Closed !== type.seatType &&
        SeatType.Kurowaku2 !== type.seatType
      );
    });
  }
}

export default new ArrangementTypes();
