/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  useRouteError
} from 'react-router-dom';
import { BaseLayout, DetailLayout } from '@sagano/station/layouts';
import PrivateRoute from './PrivateRoute';

const NotFound = lazy(() => import('@sagano/share/views/NotFound'));

const Login = lazy(() => import('@sagano/station/views/Login'));
const ResetPassword = lazy(() => import('@sagano/station/views/ResetPassword'));

const Dashboard = lazy(() => import('@sagano/station/views/Dashboard'));

const SearchInventories = lazy(
  () => import('@sagano/station/views/SearchInventories')
);
const SearchInventoriesByDate = lazy(
  () => import('@sagano/station/views/SearchInventories/ByDate')
);

const SearchInventoriesByDateByServiceId = lazy(
  () => import('@sagano/station/views/SearchInventories/ByDate/ByServiceId')
);

const ReleaseGroupArrangement = lazy(
  () => import('@sagano/station/views/ReleaseGroupArrangement')
);

const IrregularBooking = lazy(
  () => import('@sagano/station/views/IrregularBooking')
);
const IrregularBookingConfirm = lazy(
  () => import('@sagano/station/views/IrregularBooking/Confirm')
);
const LuggageBooking = lazy(
  () => import('@sagano/station/views/LuggageBooking/LuggageBooking')
);
const LuggageBookingConfirm = lazy(
  () => import('@sagano/station/views/LuggageBooking/Confirm')
);

const SearchBookings = lazy(() => import('@sagano/station/views/Booking/List'));
const BookingConfirm = lazy(
  () => import('@sagano/station/views/Booking/Confirm')
);
const BookingPayment = lazy(
  () => import('@sagano/station/views/Booking/Payment')
);
const BookingDetail = lazy(
  () => import('@sagano/station/views/Booking/Detail')
);
const BookingHistory = lazy(
  () => import('@sagano/station/views/Booking/History')
);
const BookingUpdate = lazy(
  () => import('@sagano/station/views/Booking/Update')
);
const BookingCancel = lazy(
  () => import('@sagano/station/views/Booking/Cancel')
);
const QrSearch = lazy(() => import('@sagano/station/views/QrSearch'));
const Report = lazy(() => import('@sagano/station/views/Report'));

function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={lazyView(<Dashboard />)} />
          <Route
            path="search-inventories"
            element={lazyView(<SearchInventories />)}
          />
          <Route
            path="search-inventories/:serviceDay"
            element={lazyView(<SearchInventoriesByDate />)}
          />
          <Route
            path="search-inventories/:serviceDay/:serviceId"
            element={lazyView(<SearchInventoriesByDateByServiceId />)}
          />
          <Route path="irregular-booking">
            <Route index element={lazyView(<IrregularBooking />)} />
            <Route
              path="confirm"
              element={lazyView(<IrregularBookingConfirm />)}
            />
          </Route>
          <Route path="luggage-booking">
            <Route index element={lazyView(<LuggageBooking />)} />
            <Route
              path="confirm"
              element={lazyView(<LuggageBookingConfirm />)}
            />
          </Route>
          <Route path="bookings">
            <Route index element={lazyView(<SearchBookings />)} />
            <Route path="confirm" element={lazyView(<BookingConfirm />)} />
            <Route path="payment" element={lazyView(<BookingPayment />)} />
            <Route path=":bookingId" element={<DetailLayout />}>
              <Route index element={lazyView(<BookingDetail />)} />
              <Route path="histories" element={lazyView(<BookingHistory />)} />
              <Route path="update" element={lazyView(<BookingUpdate />)} />
              <Route path="cancel" element={lazyView(<BookingCancel />)} />
            </Route>
          </Route>
          <Route path="/qr-search" element={lazyView(<QrSearch />)} />
          <Route path="/report" element={lazyView(<Report />)} />
          <Route
            path="release/:serviceDay/:serviceId"
            element={lazyView(<ReleaseGroupArrangement />)}
          />
          <Route path="*" element={lazyView(<NotFound />)} />
        </Route>
      </Route>
      <Route path="/login" element={lazyView(<Login />)} />
      <Route path="/reset-password" element={lazyView(<ResetPassword />)} />
      <Route path="*" element={lazyView(<NotFound />)} />
    </Route>
  )
);

export default router;
