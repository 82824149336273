import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@sagano/station/stores';
import User from './User';
import { LayoutHeader } from '@sagano/share/components';
import { isProd } from '@sagano/station/utils/env';
import styles from './header.module.css';

const Header: React.FC = observer(() => {
  const { stations, auth } = useStore();
  const refresh = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    sessionStorage.clear();
    window.location.replace(e.currentTarget.href);
  };
  return (
    <LayoutHeader
      webTitle="Sagano Station"
      refreshHandle={refresh}
      isProd={isProd}
    >
      {auth.isStation && (
        <p className={styles.station}>{stations.loginStationName}</p>
      )}
      <User />
    </LayoutHeader>
  );
});

export default Header;
