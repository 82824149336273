import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutUser } from '@sagano/share/components';
import stationAuth from '@sagano/station/vendors/firebase';
import { useStore } from '@sagano/station/stores';

const UserView = () => {
  const { auth } = useStore();

  const logout = useCallback(() => {
    stationAuth.logout();
  }, []);

  return <LayoutUser auth={auth} onLogout={logout} />;
};

const User = observer(UserView);

export default User;
